import { format, isValid, isDate } from 'date-fns';

const today = format(new Date());

const formatLoans = loans => {
  // console.log(loans)
  const processedLoans = {
    adverse: [],
    active: [],
    funded: [],
    prequalified: [],
    preapproved: [],
    noClosing: [],
    inactive: [],
  };

  const adverseLoanFilter = processAdverseLoans(loans);

  adverseLoanFilter[0].map(loan => {
    processedLoans.adverse.push(loan);
    return null;
  });

  const newLoansFilter = processNewLoans(adverseLoanFilter[1]);

  newLoansFilter.map(loan => {
    processedLoans[loan.category].push(loan);
    return null;
  });

  processedLoans.active.sort((a, b) => {
    return a.fmClosing - b.fmClosing;
  });

  processedLoans.funded.sort((a, b) => {
    return b.fmClosing - a.fmClosing;
  });

  return processedLoans;
};

const processAdverseLoans = loans => {
  const adverseLoans = [];
  const nonAdverseLoans = [];

  for (let loan of loans) {
    if (loan.adverseActionRequest) {
      adverseLoans.push(loan);
    } else {
      nonAdverseLoans.push(loan);
    }
  }

  return [adverseLoans, nonAdverseLoans];
};

const processNewLoans = loans => {
  const nonAdverseLoans = [];

  for (let loan of loans) {
    const {
      loanNumber,
      borrowerFirstName,
      borrowerLastName,
      propertyAddr,
      expectedCloseDate,
      funding,
      loanOfficerName,
      loanOfficerPhone,
    } = loan;

    const newLoan = {
      id: loanNumber,
      borrower: `${borrowerFirstName} ${borrowerLastName}`,
      propertyAddr,
      closing: expectedCloseDate,
      funding,
      loanOfficer: {
        loanOfficerName,
        loanOfficerPhone,
      },
    };

    const isActive = checkIfLoanActive(loan);
    const isPreQualifiedOrPreApproved = checkIfLoanPreQualifiedOrPreApproved(loan);

    if (isPreQualifiedOrPreApproved.loanPreQualifiedOrPreApproved && isPreQualifiedOrPreApproved.loanNotExpired) {
      nonAdverseLoans.push({ ...newLoan, ...isPreQualifiedOrPreApproved });
    } else if (isActive[0] === true) {
      const activeLoan = categorizeActiveLoan(newLoan, isActive[1]);
      nonAdverseLoans.push(activeLoan);
    } else {
      loan.category = 'inactive';
      nonAdverseLoans.push(loan);
    }
  }

  return nonAdverseLoans;
};

const checkIfLoanPreQualifiedOrPreApproved = loan => {
  const isLoanActive = loan.prequalified === 'False' && loan.preapproved === 'False';

  if (isLoanActive) return { loanPreQualifiedOrPreApproved: false };

  /**
   * check if client authorization received, don't add loan to processed loans if not
   */
  const loanAuthorized = loan.clientAuthorizationReceived;

  if (!loanAuthorized) return { loanPreQualifiedOrPreApproved: false };

  /**
   * check if level 1 or level 2 permission has been selected, don't add to processed loans if not
   */
  const noLoanPermissionChosen = loan.level1Permissions === 'False' && loan.level2Permissions === 'False';

  if (noLoanPermissionChosen) return { loanPreQualifiedOrPreApproved: false };

  /**
   * check to make sure loan has constraint fields and LTV set
   * level 1 permission loans only need prequalMaxLTV field set, level 2 needs all
   * these fields are empty strings in API response if not set
   */
  const loanConstraintsSet =
    loan.level2Permissions === 'False'
      ? loan.prequalMaxLTV
      : loan.prequalMinLoanAmount &&
        loan.prequalMaxLoanAmount &&
        loan.prequalMinPurchasePrice &&
        loan.prequalMaxPurchasePrice &&
        loan.prequalMaxLTV;

  if (!loanConstraintsSet) {
    return { loanPreQualifiedOrPreApproved: false };
  }

  /**
   * determine if loan is prequalified or preapproved (if preapproved both are true)
   */
  const loanPreQualified = loan.prequalified === 'True';
  const loanPreApproved = loan.preapproved === 'True';


  const isLoanNotExpired = (loanPreQualified, loanPreApproved) => {
    if (loanPreApproved) return true;

    if (loanPreQualified && loan.prequalExpirationDate > today) return true;

    return false;
  };

  const getLoanCategory = (loanPreQualified, loanPreApproved) => {
    const loanCategory = loanPreQualified && !loanPreApproved ? 'prequalified': loanPreApproved ? 'preapproved' : null;
    return loanCategory;
  };

  return {
    loanCity: loan.propertyAddr.replace('<br />', ' ').replace(/[0-9]/g, ''),
    category: getLoanCategory(loanPreQualified, loanPreApproved),
    loanNotExpired: isLoanNotExpired(loanPreQualified, loanPreApproved),
    loanPreQualifiedOrPreApproved: loanPreQualified || loanPreApproved,
  };
};

const checkIfLoanActive = loan => {
  const milestoneKeys = [
    'mloSetupComplete',
    'submittedToUnderwriting',
    'conditionallyApproved',
    'initialUnderwritingComplete',
    'p1ConditionResolutionComplete',
    'underwrtitingIIConditionReviewComplete',
    'piiFinalCondition',
    'underwritingIIIClearToClose',
    'docProp',
    'funding',
  ];

  let loanIsActive = false;
  let milestones = {};

  milestoneKeys.map(key => {
    milestones[key] = format(loan[key]);
    const tempDate = new Date(milestones[key]);

    if (isValid(tempDate)) {
      loanIsActive = true;
    }

    return null;
  });

  return [loanIsActive, milestones];
};

const categorizeActiveLoan = (loan, milestones) => {
  const { funding } = milestones;
  const fmFunding = new Date(format(funding));
  const fmClosing = new Date(format(loan.closing));

  loan.fmFunding = fmFunding;
  loan.fmClosing = fmClosing;


  if (isDate(fmFunding)) {
    if (isValid(fmFunding) && funding <= today) {
      loan.category = 'funded';
      return loan;
    } else if (!isValid(fmFunding) && !isValid(fmClosing)) {
      loan.category = 'noClosing';
      return loan;
    }
  }

  if (isValid(fmClosing) || funding > today) {
    loan.category = 'active';
    return loan;
  }
};

export { formatLoans, categorizeActiveLoan };
