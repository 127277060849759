import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

// import assets
import { ReactComponent as LoansIcon } from '../../assets/icons/loans-icon.svg';
import { ReactComponent as DownArrow } from '../../assets/ui/down-arrow.svg';

// import styled components
import {
  LoanHeaderAddress,
  LoanHeaderContacts,
  LoanHeaderContainer,
  LoanHeaderContent,
  LoanHeaderContentEntry,
  LoanHeaderContentEntryContact,
  LoanHeaderContentSection,
  LoanHeaderPersonLabel,
  LoanHeaderTitle,
} from './styled';

function LoanHeader({ loanData }) {
  /**
   * probably redo how this is done, it works but eh could be cleaner/less mutating
   */
  let defaultInfo = {
    propertyAddress: '',
    contacts: {
      borrower: {
        name: '',
        email: '',
        address: '',
      },
      coBorrower: {
        exists: false,
        name: '',
        email: '',
        address: '',
      },
      mlo: {
        name: '',
        email: '',
        phone: '',
        nmls: '',
      },
    },
  };

  if (loanData.hasOwnProperty('contacts')) {
    defaultInfo = { ...loanData };
  }

  const {
    propertyAddress,
    contacts: { borrower, coBorrower, mlo },
  } = defaultInfo;

  if (loanData.loanType === 'Prequalification-TX') {
    loanData.loanType = 'Prequalified'
  }

  return (
    <LoanHeaderContainer>
      <LoanHeaderTitle>
        <h1>{`${loanData.loanType} Loan`}</h1>
      </LoanHeaderTitle>
      <LoanHeaderContent>
        <LoanHeaderAddress>
          <LoansIcon />
          <p>{ReactHtmlParser(propertyAddress)}</p>
        </LoanHeaderAddress>
        <LoanHeaderContacts>
          <LoanHeaderContentSection>
            <LoanHeaderContentEntry>
              <LoanHeaderPersonLabel>Borrower</LoanHeaderPersonLabel>
              <span tabIndex="0">
                <h3>
                  {borrower.name}
                  <DownArrow className="borrower-indicator" />
                </h3>
                <LoanHeaderContentEntryContact>
                  <h4>Email</h4>
                  <a href={`mailto:${borrower.email}`} target="_blank" rel="noopener noreferrer">
                    {borrower.email}
                  </a>
                  <h4>Address</h4>
                  <p>{ReactHtmlParser(borrower.address)}</p>
                </LoanHeaderContentEntryContact>
              </span>
            </LoanHeaderContentEntry>
          </LoanHeaderContentSection>
          {coBorrower.exists ? (
            <LoanHeaderContentSection>
              <LoanHeaderContentEntry>
                <LoanHeaderPersonLabel>Co-Borrower</LoanHeaderPersonLabel>
                <span tabIndex="0">
                  <h3>
                    {coBorrower.name}
                    <DownArrow className="borrower-indicator" />
                  </h3>
                  <LoanHeaderContentEntryContact>
                    <h4>Email</h4>
                    <a href={`mailto:${coBorrower.email}`} target="_blank" rel="noopener noreferrer">
                      {coBorrower.email}
                    </a>
                    <h4>Address</h4>
                    <p>{ReactHtmlParser(coBorrower.address)}</p>
                  </LoanHeaderContentEntryContact>
                </span>
              </LoanHeaderContentEntry>
            </LoanHeaderContentSection>
          ) : null}
          <LoanHeaderContentSection>
            <LoanHeaderContentEntry>
              <LoanHeaderPersonLabel>Mortgage Loan Officer</LoanHeaderPersonLabel>
              <span tabIndex="0">
                <h3>
                  {mlo.name}
                  <DownArrow className="borrower-indicator" />
                </h3>
                <LoanHeaderContentEntryContact>
                  <h4>NMLS#</h4>
                  <p>{mlo.nmls}</p>
                  <h4>Email</h4>
                  <a href={`mailto:${mlo.email}`} target="_blank" rel="noopener noreferrer">
                    {mlo.email}
                  </a>
                  <h4>Phone</h4>
                  <p>{mlo.phone}</p>
                </LoanHeaderContentEntryContact>
              </span>
            </LoanHeaderContentEntry>
          </LoanHeaderContentSection>
          {!coBorrower.exists ? <LoanHeaderContentSection /> : null}
        </LoanHeaderContacts>
      </LoanHeaderContent>
    </LoanHeaderContainer>
  );
}

export default LoanHeader;

LoanHeader.propTypes = {
  loanData: PropTypes.object.isRequired,
};
