/**
 * using env var instead of specifiying LIVE/DEV like below is preferred
 */

/**
 * production API
 */
// export const LIVE_API_BASE_URL = 'https://devpps.loanforceinsider.com';

/**
 * development API
 */
// export const DEV_API_BASE_URL = 'https://devpps.loanforceinsider.com';

/**
 * use env var to determine
 */
export const API_BASE_URL =
  process.env.REACT_APP_BUILD_ENV === 'production'
    ? 'https://devpps.loanforceinsider.com'
    : 'https://devpps.loanforceinsider.com';

/**
 * for quick switching during development
 */
// export const API_BASE_URL = 'https://devpps.loanforceinsider.com';
// export const API_BASE_URL = 'https://devpps.loanforceinsider.com';

/**
 * geocoding is currently turned off
 * this is OneTrust's account API key
 * if this becomes a needed feature will have to work out how to deal with it
 */
export const GOOGLE_GEOCODE_API_KEY = 'AIzaSyBEDijsk3vOPVLAuYKZu4wir6Y3tpu1xXQ';
