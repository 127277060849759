import { useContext, useState } from 'react';
import { format } from 'date-fns';

// import config
import { API_BASE_URL } from '../../../../util/config';

// import context
import { AppContext } from '../../../App';
import { LoggedInContext } from '../../../Context/LoggedInContext';

const formatLetterRequest = async (
  loanType,
  letterInformation,
  accessToken,
  setSendingRequest,
  setLetterData,
  toggleModal
) => {
  setSendingRequest(true);

  const regenerateLetterUrl = `${API_BASE_URL}/api/LoanLetter/RegenerateLetter`;

  const letterType = loanType => {
    switch (loanType) {
      case 'Prequalification-TX':
        return 'Prequalification-TX';
      case 'Pre-Qualified':
        return 'Prequalification';
      case 'Pre-Approved':
        return 'Preapproval';
      default:
        return 'Prequalification-TX';
    }
  };

  const formattedLetterRequest = {
    ...letterInformation,
    LetterType: letterType(loanType),
    createdOn: format(new Date(), 'MM/DD/YYYY'),
  };

  const jsonFormattedLetterRequest = JSON.stringify(formattedLetterRequest);

  const regenerateLetterRequest = await fetch(regenerateLetterUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${accessToken}`,
    },
    responseType: 'arraybuffer',
    dataType: 'blob',
    body: jsonFormattedLetterRequest,
  });

  const regeneratedLetterPdf = await regenerateLetterRequest.blob();

  setSendingRequest(false);

  const data = new Blob([regeneratedLetterPdf], { type: 'application/pdf' });

  setLetterData(data);

  toggleModal(data);

  /**
   * initially opened letter preview in new tab, replaced with using modal overlay
   * gets blocked by adblockers and stuff so prefer modal (also consistency with 'View Letter')
   */

  // if ie11 use its thing to open blob
  // if (window.navigator.msSaveOrOpenBlob) {
  //   window.navigator.msSaveOrOpenBlob(data);
  // } else {
  //   if (openInWindow) {
  //     const url = URL.createObjectURL(data);
  //     // window.open(url, '_blank');
  //     const newWindow = window.open('', '_blank');
  //     newWindow.location.href = url;
  //     // buttonRef.current.click();
  //   }
  // }
};

// const openNewWindow = data => {
//   if (!data) return;
//   const url = URL.createObjectURL(data);

//   window.open(url);
// };

function useRegenerateLetter(loanType, letterInformation) {
  const {
    state: {
      userState: { accessToken },
    },
  } = useContext(LoggedInContext);

  const {
    functions: { toggleModal },
  } = useContext(AppContext);

  const [sendingRequest, setSendingRequest] = useState(false);
  const [letterData, setLetterData] = useState(null);

  const handleLetterRequest = () => {
    formatLetterRequest(loanType, letterInformation, accessToken, setSendingRequest, setLetterData, toggleModal);
  };

  return [handleLetterRequest, sendingRequest, letterData];
}

export default useRegenerateLetter;
