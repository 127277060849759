import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import ReactHtmlParser from 'react-html-parser';

//import context
import { LoggedInContext } from '../Context/LoggedInContext';

// import styled components
import { DashboardItemLink, DashboardLoanCard, DashboardLoanCardTitle, DashboardLoanCardBody } from './styled';

const ActiveOrFundedLoanCardBody = ({ dateTitle, displayDate, propertyAddr }) => (
  <DashboardLoanCardBody>
    <p>{ReactHtmlParser(propertyAddr)}</p>
    <hr />
    <h3>{dateTitle}</h3>
    <p>{displayDate}</p>
  </DashboardLoanCardBody>
);

const PrequalifiedOrPreapprovedLoanCardBody = ({ loanCity }) => (
  <DashboardLoanCardBody>
    <hr />
    <p className="prequal">{loanCity}</p>
  </DashboardLoanCardBody>
);

/**
 * normally I would split out these three components into three separate files
 * but they are all only used together here so together they will stay ¯\\_(ツ)_/¯
 * prop types for all three at bottom
 */
function DashboardLoanItem(props) {
  const {
    functions: { handleIndividualLoan },
  } = useContext(LoggedInContext);

  const { borrower, propertyAddr, id, type } = props;

  return (
    <DashboardItemLink to={`/loans/${id}`} state={{ type }}>
      <DashboardLoanCard onMouseOver={() => handleIndividualLoan(id)}>
        <DashboardLoanCardTitle>
          <h2>{borrower}</h2>
        </DashboardLoanCardTitle>
        {type === 'prequalified' || type === 'preapproved'? (
          <PrequalifiedOrPreapprovedLoanCardBody {...props} />
        ) : (
          <ActiveOrFundedLoanCardBody
            propertyAddr={propertyAddr}
            dateTitle={type === 'active' ? 'Expected Closing' : 'Funding Date'}
            displayDate={format(props[type === 'active' ? 'closing' : 'funding'], 'M/D/YYYY')}
          />
        )}
      </DashboardLoanCard>
    </DashboardItemLink>
  );
}

export default memo(DashboardLoanItem);

DashboardLoanItem.propTypes = {
  // all loan types have these
  borrower: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  closing: PropTypes.string.isRequired,
  funding: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  loanOfficer: PropTypes.object.isRequired,
  propertyAddr: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,

  // only active & funded loans have these
  fmClosing: PropTypes.object,
  fmFunding: PropTypes.object,

  // only prequalified & preapproved loans have these
  loanCity: PropTypes.string,
  loanNotExpired: PropTypes.bool,
  loanPreQualifiedOrPreApproved: PropTypes.bool,
};

PrequalifiedOrPreapprovedLoanCardBody.propTypes = {
  loanCity: PropTypes.string.isRequired,
};

ActiveOrFundedLoanCardBody.propTypes = {
  propertyAddr: PropTypes.string.isRequired,
  dateTitle: PropTypes.string.isRequired,
  displayDate: PropTypes.string.isRequired,
};
