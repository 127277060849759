import { useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';

// import config
import { API_BASE_URL } from '../../../../util/config';

// import context
import { LoggedInContext } from '../../../Context/LoggedInContext';

const formatLetterRequest = async (loanType, letterInformation, accessToken, setPreloadedLetterData, setLoading) => {
  setLoading(true);

  const regenerateLetterUrl = `${API_BASE_URL}/api/LoanLetter/RegenerateLetter`;

  const letterType = loanType => {
    switch (loanType) {
      case 'Prequalification-TX':
        return 'Prequalification-TX';
      case 'Pre-Qualified':
        return 'Prequalification';
      case 'Pre-Approved':
        return 'Preapproval';
      default:
        return 'Prequalification-TX';
    }
  };

  const formattedLetterRequest = {
    ...letterInformation,
    LetterType: letterType(loanType),
    createdOn: format(new Date(), 'MM/DD/YYYY'),
  };

  const jsonFormattedLetterRequest = JSON.stringify(formattedLetterRequest);

  const regenerateLetterRequest = await fetch(regenerateLetterUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${accessToken}`,
    },
    responseType: 'arraybuffer',
    dataType: 'blob',
    body: jsonFormattedLetterRequest,
  });

  const regeneratedLetterPdf = await regenerateLetterRequest.blob();

  const data = new Blob([regeneratedLetterPdf], { type: 'application/pdf' });

  setPreloadedLetterData(data);

  setLoading(false);
};

function usePreloadRegeneratedLetter(loanType, letterInformation) {
  const {
    state: {
      userState: { accessToken },
    },
  } = useContext(LoggedInContext);

  const { borrowerFirstName, borrowerLastName, LoanNumber } = letterInformation;
  const fileName = `${borrowerFirstName}-${borrowerLastName}-${loanType}-${LoanNumber}-letter-${format(
    new Date(),
    'MMDDYYYY:hhmmss'
  )}`.toLowerCase();

  const [loading, setLoading] = useState(false);
  const [preloadedLetterData, setPreloadedLetterData] = useState(null);

  useEffect(() => {
    formatLetterRequest(loanType, letterInformation, accessToken, setPreloadedLetterData, setLoading);
  }, []);

  return [preloadedLetterData, fileName, loading];
}

export default usePreloadRegeneratedLetter;
